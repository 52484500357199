<script setup>
	setResponseStatus(404);
	import { useI18n } from "vue-i18n";

	const { t } = useI18n();
	let notFound = t("Not Found");
	useSeoMeta({
		title: "Vispetta - " + notFound,
		ogTitle: "Vispetta - " + notFound,
		description: "Vispetta Rent",
		ogDescription: "Vispetta Rent",
		ogImage: "/images/landing.jpg",
		twitterCard: "summary_large_image",
	});
</script>

<template>
	<div
		class="relative flex items-top justify-center min-h-screen bg-gray-100 dark:bg-gray-900 sm:items-center sm:pt-0"
	>
		<div class="max-w-xl mx-auto sm:px-6 lg:px-8">
			<div class="flex items-center pt-8 sm:justify-start sm:pt-0">
				<div
					class="px-4 text-lg text-gray-500 border-r border-gray-400 tracking-wider"
				>
					404
				</div>

				<div class="ml-4 text-lg text-gray-500 uppercase tracking-wider">
					{{ notFound }}
				</div>
			</div>
		</div>
	</div>
</template>
